import { ColumnDef } from "@tanstack/react-table"
import { ArrowUpDown, MoreHorizontal, Trash2 } from "lucide-react"
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import { Database } from '@/types/supabase'
import { Button } from '@/components/ui/button'
import { Checkbox } from "@/components/ui/checkbox"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu"

export type InvoiceType = Database['public']['Tables']['invoices']['Row']

const intl = new Intl.DateTimeFormat('lt-LT', { dateStyle: 'short' })
const toDate = (date: string) => date ? intl.format(new Date(date)) : '';
const toSumEur = (sum: number) => new Intl.NumberFormat('lt-LT', { style: 'currency', currency: 'EUR' }).format(sum);

interface SortableHeaderProps {
  column: {
    toggleSorting: (value: boolean) => void;
    getIsSorted: () => string | undefined;
  };
  children?: ReactNode;
}

const SortableHeader = ({ column, children }: SortableHeaderProps): JSX.Element => {
  const isSorted = column.getIsSorted() == "asc" ? true : false;
  return (
    <Button
      variant="ghost"
      className="p-0"
      onClick={() => column.toggleSorting(isSorted)}
    >
      {children}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  )
}

export const reportsColumns: ColumnDef<InvoiceType>[] = [
  {
    accessorKey: "code",
    header: ({ column }) => <SortableHeader column={column}>Kodas</SortableHeader>,
    cell: ({ row }) => {
      return (
        <Link
          to={`/invoices/${row.original.id}`}
          className="whitespace-nowrap font-bold hover:underline"
        >
          {row.getValue("code")}
        </Link>
      )
    },
  },
  {
    accessorKey: "date",
    header: ({ column }) => <SortableHeader column={column}>Data</SortableHeader>,
    cell: ({ row }) => {
      return (
        <div className="whitespace-nowrap">
          {toDate(row.getValue("date"))}
        </div>
      )
    },
    enableGlobalFilter: true,
    enableColumnFilter: true,
    filterFn: 'dater',
    // filterFn: (rows, id, filterValue) => {
    //   console.log({ rows, id, filterValue })
    //   return rows?.filter(row => {
    //     const date = new Date(row.original.date);
    //     return date.getMonth() === Number(filterValue);
    //   });
    // }

  },
  {
    accessorKey: "contact_name",
    header: ({ column }) => <SortableHeader column={column}>Klientas</SortableHeader>,
    cell: ({ row }) => {
      const { client_type, contact_name } = row.original;
      const renderName = client_type ? `${client_type} ${contact_name}` : contact_name;
      return (
        <div className="whitespace-nowrap">
          {renderName}
        </div>
      )
    },
  },
  {
    accessorKey: "total",
    header: ({ column }) => <SortableHeader column={column}>Suma</SortableHeader>,
    cell: ({ row }) => {
      return (
        <div className="whitespace-nowrap text-right">
          {toSumEur(row.getValue("total"))}
        </div>
      )
    },
  },
];
