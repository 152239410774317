import { useState } from "react";
import { toast } from "sonner";
import { LoaderCircle } from "lucide-react";

import { invoicesColumns } from "@/components/tables/invoicesColumns";
import { InvoicesDataTable } from '@/components/tables/InvoicesDataTable';
import { useStore } from '@/store';
import { fetchAllInvoices } from "@/api";
import { Button } from "@/components/ui/button";

export function Invoices(): JSX.Element {
  const { invoices, setInvoices, clients } = useStore(s => s);
  const [loading, setLoading] = useState(false);
  const renderInvoices = invoices?.map((invoice) => {
    const client = clients.find((client) => client.id === invoice.client_id);
    return {
      ...invoice,
      client_type: client?.type,
    };
  });

  function handleButtonClick() {
    setLoading(true);
    fetchAllInvoices().then(({ data, error }) => {
      if (error) {
        toast.error(error.message);
        throw error;
      }
      
      setInvoices(data);
      toast.success('Visos sąskaitos sėkmingai užkrautos');
    }).catch((error) => {
      toast.error(error.message);
      throw error;
    }).finally(() => {
      setLoading(false);
    });
  }

  const buttonTitle = loading ? 'Užkraunama...' : 'Užkrauti visas sąskaitas';

  if (!invoices) return <div>Kraunasi...</div>;

  return (
    <>
      <h1 className="text-2xl my-4 font-bold">Sąskaitos:</h1>
      <InvoicesDataTable data={renderInvoices} columns={invoicesColumns} />
      <Button type="button" onClick={handleButtonClick} disabled={loading} className="my-4">
        {buttonTitle}
        {loading && <LoaderCircle className="ml-2 animate-spin" />}
      </Button>
    </>
  )
}
