import { useRouteLoaderData } from 'react-router-dom'

import { ProductForm } from '@/components/ProductForm'
import { UPDATE_PRODUCT_ROUTE_ID } from '@/router'

interface ProductLoaderData {
  id: number
  name: string
  contact_name: string
  code: string
  vat: string
  phone: string
  address: string
  contract_id: string
  tenant_id: number
  type: string
}

export function UpdateProduct(): JSX.Element {
  const data = useRouteLoaderData(UPDATE_PRODUCT_ROUTE_ID);

  return (
    <>
      <ProductForm {...data as ProductLoaderData} />
    </>
  )
}
