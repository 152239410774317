import { Link, useMatches, } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { supabase } from '@/supabaseClient'

interface NavigationLinkProps {
  to: string;
  children: string;
}

const NavigationLink = ({ to, children }: NavigationLinkProps) => {
  const isActive = useMatches().at(-1)?.pathname === to;

  return (
    <Button
      className={`my-2 ${isActive ? 'underline' : ''}`}
      asChild
      variant="link"
    >
      <Link to={to}>
        {children}
      </Link>
    </Button>
  )
}

export function Nav(): JSX.Element {

  return (
    <nav className="py-4 sticky top-0 bg-white z-10">
      <Button className="my-2" asChild>
        <Link to="/create">
          Nauja sąskaita
        </Link>
      </Button>
      <NavigationLink to="/">
        Sąskaitos
      </NavigationLink>
      <NavigationLink to="/clients">
        Klientai
      </NavigationLink>
      <NavigationLink to="/products">
        Produktai
      </NavigationLink>
      <NavigationLink to="/reports">
        Ataskaitos
      </NavigationLink>
      <NavigationLink to="/settings">
        Nustatymai
      </NavigationLink>
      <Button type="button" variant='outline' onClick={() => supabase.auth.signOut()}>
        Atsijungti
      </Button>
    </nav>
  )
}
