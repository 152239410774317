import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { useEffect, useState } from "react"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Database } from '@/types/supabase'

export type InvoiceType = Database['public']['Tables']['invoices']['Row']

interface InvoicesDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[],
  form: any,
  disabled?: boolean,
  children?: React.ReactNode,
  onRemoveRow: (rowIndex: number) => void,
}

export function InvoiceItemsDataTable<TData, TValue>({
  columns,
  data,
  form,
  disabled = true,
  children,
  onRemoveRow,
}: InvoicesDataTableProps<TData, TValue>) {
  const [expanded, setExpanded] = useState<ExpandedState>({})
  // const [tableData, setTableData] = useState(data)
  const table = useReactTable({
    // data: tableData,
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    meta: {
      form,
      disabled,
      removeRow: (rowIndex: number) => onRemoveRow(rowIndex),
      // updateData: (rowIndex, columnId, value) => {
      //   setTableData(old => {
      //     return old.map((row, index) => {
      //       if (index !== rowIndex) return row;
      //       return {
      //         ...old[rowIndex]!,
      //         [columnId]: value,
      //       }
      //     })
      //   }
      //   )
      // },
    },
    // debugTable: true,
  })

  // useEffect(() => setTableData(data), [data]);

  const total = table.getRowModel().rows.reduce((acc, row) => acc + row.original.price * row.original.quantity, 0)

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(({ id, column, getContext }) => (
                <TableHead key={id}>
                  {flexRender(column.columnDef.header, getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map(({ id, getVisibleCells }) => (
              <TableRow key={id}>
                {getVisibleCells().map(({ id, column, getContext }) => (
                  <TableCell key={id}>
                    {flexRender(column.columnDef.cell, getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-14 text-center">
                Sąskaitoje nėra prekių
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={columns.length - 3} className="text-center">
              {children}
            </TableCell>
            <TableCell colSpan={1} className="text-right">
              <b>Viso</b>
            </TableCell>
            <TableCell colSpan={1} className="text-right">
              {`${(total).toFixed(2).toString().replace('.', ',')} €`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
