import { clientsColumns } from "@/components/tables/clientsColumns";
import { ClientsDataTable } from '@/components/tables/ClientsDataTable';
import { useStore } from '@/store';

export function Clients(): JSX.Element {
  const { clients } = useStore(s => s)

  console.log({ clients })

  if (!clients) return <div>Loading...</div>

  return (
    <>
      <h1 className="text-2xl my-4 font-bold">Klientai:</h1>
      <ClientsDataTable data={clients} columns={clientsColumns} />
    </>
  )
}
