import { InvoiceForm } from '@/components/InvoiceForm'


export function CreateInvoice(): JSX.Element {
  return (
    <>
      <h1 className="text-2xl my-4 font-bold">Nauja sąskaita</h1>
      <InvoiceForm />
    </>
  )
}
