import { Outlet, useRouteLoaderData } from 'react-router-dom';
import { useEffect } from 'react';
import { Toaster } from "@/components/ui/sonner"

import { Nav } from '@/components/Nav';
import { DialogRegistry } from '@/components/DialogRegistry';
import { ROOT_ROUTE_ID } from '@/router';
import { useStore } from '@/store';
import {
  ProductType,
  InvoiceType,
  ClientType,
  TenantType,
} from '@/types';

interface LoaderData {
  tenant: TenantType
  invoices: InvoiceType[]
  clients: ClientType[]
  products: ProductType[]
}

export function App(): JSX.Element {
  const loaderData = useRouteLoaderData(ROOT_ROUTE_ID);
  const { setInitialData } = useStore(s => s);

  useEffect(
    () => setInitialData(loaderData as LoaderData),
    [setInitialData, loaderData]
  );

  return (
    <>
      <div className="container">
        <Nav />
        <Outlet />
        <Toaster richColors />
        <DialogRegistry />
      </div>
    </>
  );
}
