import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from '@/lib/zod-lt'

import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { useStore } from '@/store';

import { CLIENT_TYPES } from '@/constants';

const NAME_FIELD = 'name'
const CONTACT_NAME_FIELD = 'contact_name'
const TYPE_FIELD = 'type' // dropdown
const CODE_FIELD = 'code'
const VAT_FIELD = 'vat'
const PHONE_FIELD = 'phone'
const ADDRESS_FIELD = 'address'
const CONTRACT_ID_FIELD = 'contract_id'
const ID_FIELD = 'id'

const clientSchema = z.object({
  [NAME_FIELD]: z.string().min(2),
  [CONTACT_NAME_FIELD]: z.string().optional(),
  [TYPE_FIELD]: z.string().optional(),
  [CODE_FIELD]: z.string().optional(),
  [VAT_FIELD]: z.string().optional(),
  [PHONE_FIELD]: z.string().optional(),
  [ADDRESS_FIELD]: z.string().optional(),
  [CONTRACT_ID_FIELD]: z.string().optional(),
  [ID_FIELD]: z.number().optional(),
});

type ClientFormProps = {
  onClientFormSubmit?: (client: z.infer<typeof clientSchema>) => void
  id?: number
  name?: string
  contact_name?: string
  code?: string
  vat?: string
  phone?: string
  address?: string
  contract_id?: string
  tenant_id?: number
  type?: string
}

export function ClientForm({ onClientFormSubmit, ...loaderData }: ClientFormProps): JSX.Element {
  const {
    clients,
    products,
    upsertClient,
    isUpsertClientLoading,
    invoices
  } = useStore(s => s);

  const form = useForm<z.infer<typeof clientSchema>>({
    mode: 'all',
    resolver: zodResolver(clientSchema),
    defaultValues: {
      [NAME_FIELD]: loaderData?.name ?? '',
      [CONTACT_NAME_FIELD]: loaderData?.contact_name ?? '',
      [TYPE_FIELD]: loaderData?.type ?? '',
      [CODE_FIELD]: loaderData?.code ?? '',
      [VAT_FIELD]: loaderData?.vat ?? '',
      [PHONE_FIELD]: loaderData?.phone ?? '',
      [ADDRESS_FIELD]: loaderData?.address ?? '',
      [CONTRACT_ID_FIELD]: loaderData?.contract_id ?? '',
      [ID_FIELD]: loaderData?.id,
    },
  });


  if (Object.keys(form.formState.errors).length > 0) {
    console.debug(form.formState.errors)
  }

  async function onSubmit(formData: z.infer<typeof clientSchema>): Promise<void> {
    const newClient = await upsertClient(formData);
    onClientFormSubmit?.(newClient);
  }

  if (!clients || !products) return <></>;

  const buttonIdleLabel = loaderData?.id ? 'Atnaujinti' : 'Sukurti';
  const buttonLoadingLabel = loaderData?.id ? 'Atnaujinama...' : 'Kuriama...';
  const buttonLabel = isUpsertClientLoading ? buttonLoadingLabel : buttonIdleLabel;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="w-full space-y-6"
      >
        <div className="grid grid-cols-2 gap-x-6 gap-y-3">
          <FormField control={form.control} name={NAME_FIELD} render={({ field }) => (
            <FormItem>
              <FormLabel>Pavadinimas*</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                Juridinio asmens pavadinimas arba vardas, pavardė
              </FormDescription>
              <FormMessage />
            </FormItem>
          )} />
          <FormField control={form.control} name={CODE_FIELD} render={({ field }) => (
            <FormItem>
              <FormLabel>Kodas</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                Juridinio/fizinio asmens kodas
              </FormDescription>
              <FormMessage />
            </FormItem>
          )} />
          <FormField control={form.control} name={TYPE_FIELD} render={({ field }) => (
            <FormItem>
              <FormLabel>Tipas</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Pasirinkite kliento tipą" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value={null}>-</SelectItem>
                  {CLIENT_TYPES.map((type) => (
                    <SelectItem key={type.value} value={type.value}>
                      {type.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormDescription>
                {/* You can manage email addresses in your{" "} */}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )} />
          <FormField control={form.control} name={VAT_FIELD} render={({ field }) => (
            <FormItem>
              <FormLabel>PVM kodas</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                PVM mokėtojo kodas
              </FormDescription>
              <FormMessage />
            </FormItem>
          )} />
          <FormField control={form.control} name={PHONE_FIELD} render={({ field }) => (
            <FormItem>
              <FormLabel>Telefonas</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )} />
          <FormField control={form.control} name={ADDRESS_FIELD} render={({ field }) => (
            <FormItem>
              <FormLabel>Adresas</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )} />
          <FormField control={form.control} name={CONTACT_NAME_FIELD} render={({ field }) => (
            <FormItem>
              <FormLabel>Kontaktinis asmuo</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                Vardas, pavardė (jeigu skiriasi nuo pavadinimo)
              </FormDescription>
              <FormMessage />
            </FormItem>
          )} />
          <FormField control={form.control} name={CONTRACT_ID_FIELD} render={({ field }) => (
            <FormItem>
              <FormLabel>Sutarties numeris</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )} />
          {form.getValues(ID_FIELD) && (
            <FormField control={form.control} name={ID_FIELD} render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input type="hidden" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )} />
          )}
        </div>
        <Button
          type="submit"
          className="w-full"
          size="lg"
          disabled={isUpsertClientLoading}
        >
          {buttonLabel}
        </Button>
      </form>
    </Form>
  )
}
