import { useState } from "react";

import { reportsColumns } from "@/components/tables/reportsColumns";
import { ReportsDataTable } from '@/components/tables/ReportsDataTable';
import { useStore } from '@/store';

export function Reports(): JSX.Element {
  const { invoices, setInvoices, clients } = useStore(s => s);
  const [loading, setLoading] = useState(false);
  const renderInvoices = invoices?.map((invoice) => {
    const client = clients.find((client) => client.id === invoice.client_id);
    return {
      ...invoice,
      client_type: client?.type,
    };
  });

  if (!invoices) return <div>Kraunasi...</div>;

  return (
    <>
      <h1 className="text-2xl my-4 font-bold">Ataskaitos:</h1>
      <ReportsDataTable data={renderInvoices} columns={reportsColumns} />
    </>
  )
}
