import { useStore } from '@/store';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useEffect } from 'react';

export interface DialogType {
  onConfirm: () => Promise<void>
  title: string
  message: string
  confirmText: string
  time?: Date
}

export function DialogRegistry(): JSX.Element {
  const { dialogs, closeDialog, openDialog } = useStore(s => s);

  async function handleConfirmClick(dialog: DialogType) {
    await dialog.onConfirm();
    closeDialog(dialog.time);
  }


  if (!dialogs.length) <></>;

  return (
    <>
      {dialogs.map(dialog => {
        return (
          <AlertDialog open={true} key={dialog.time}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {dialog.title}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {dialog.message}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => closeDialog(dialog.time)}>
                  Uždaryti
                </AlertDialogCancel>
                <AlertDialogAction onClick={() => handleConfirmClick(dialog)}>
                  {dialog.confirmText}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )
      })}
    </>
  );
}
