import { ChevronsUpDown, PlusCircle } from 'lucide-react'
import { useState, createRef, useEffect } from 'react'
import Fuse from 'fuse.js'

import { cn } from '@/utils'
import { Button } from '@/components/ui/button'
import { FormControl } from '@/components/ui/form'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandList,
  CommandInput,
  CommandItem
} from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"

type Option = {
  name: string
  id: number | string
}

type InvoiceComboboxProps = {
  selectedOption: Option | null,
  onSelect: (option: Option) => void
  options: Option[],
  selectLabel?: string,
  searchLabel?: string,
  notFoundLabel?: string,
  initalOption?: Option | undefined,
  children?: React.ReactNode,
  dialogTitle?: string,
  dialogDescription?: string,
  isOpen: boolean,
  onComboboxOpenChange: (isOpen: boolean) => void,
  isDialogOpen?: boolean,
  onDialogOpenChange?: (isOpen: boolean) => void,
  disabled?: boolean,
}

const FUSE_OPTIONS = {
  keys: [
    "name",
    "id",
  ]
};

export function Combobox({
  options,
  selectLabel = 'Pasirinkite...',
  searchLabel = 'Ieškoti...',
  notFoundLabel = 'Rezultatų nerasta.',
  dialogTitle,
  dialogDescription,
  isOpen,
  onComboboxOpenChange,
  isDialogOpen,
  onDialogOpenChange,
  selectedOption,
  onSelect,
  children,
  disabled = false,
}: InvoiceComboboxProps): JSX.Element {
  const [search, setSearch] = useState<string>('');
  const commandListRef = createRef<HTMLDivElement>();

  const fuse = new Fuse(options, FUSE_OPTIONS);
  const fusedOptions = fuse.search(search).map(({ item }) => item);
  const renderOptions = search ? fusedOptions : options;

  function handleSelect(option: Option): void {
    onSelect(option);
    onComboboxOpenChange(false);
  };

  useEffect(() => {
    if (commandListRef.current) {
      commandListRef.current.scrollTop = 0;
    }
  }, [search, isOpen]);
  
  return (
    <>
      <Popover
        open={isOpen}
        onOpenChange={onComboboxOpenChange}
      >
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              variant="outline"
              role="combobox"
              className={cn(
                'w-full justify-between text-left flex',
                !selectedOption && 'text-muted-foreground'
              )}
              disabled={disabled}
            >
              <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                {selectedOption ? selectedOption.name : selectLabel}
              </div>
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent className="w-[--radix-popper-anchor-width] p-0 max-h-[--radix-popper-available-height]">
          <Command shouldFilter={false} >
            <CommandInput placeholder={searchLabel} onValueChange={setSearch} />
            <CommandEmpty>{notFoundLabel}</CommandEmpty>
            <Dialog
              open={isDialogOpen}
              onOpenChange={onDialogOpenChange}
            >
              <DialogTrigger asChild>
                <Button className="mx-1 my-2" size="lg">
                  <PlusCircle className="mr-2 h-4 w-4 shrink-0 opacity-50" />
                  Sukurti naują
                </Button>
              </DialogTrigger>
              <DialogContent className="max-w-full">
                <DialogHeader>
                  {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
                  {dialogDescription && <DialogDescription>{dialogDescription}</DialogDescription>}
                </DialogHeader>
                <div className="flex items-center space-x-2">
                  {children}
                </div>
              </DialogContent>
            </Dialog>
            <CommandGroup>
              <CommandList ref={commandListRef}>
                {renderOptions.map((option) => (
                  <CommandItem
                    key={option.id}
                    value={option.id}
                    disabled={option.disabled}
                    className='whitespace-nowrap overflow-hidden text-ellipsis w-full'
                    onSelect={() => handleSelect(option)}
                  >
                    {option.name}
                  </CommandItem>
                )
                )}
              </CommandList>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  )
}