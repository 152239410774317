import { ColumnDef } from "@tanstack/react-table"
import { Trash2 } from "lucide-react"
import { useEffect } from 'react';

import { Database } from '@/types/supabase'
import { Button } from '@/components/ui/button'
import { Input } from "@/components/ui/input"
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'

export type InvoiceType = Database['public']['Tables']['invoices']['Row']

// const intl = new Intl.DateTimeFormat('lt-LT', { dateStyle: 'short' })
// const toDate = (date: string) => date ? intl.format(new Date(date)) : '';
const toSumEur = (sum: number) => new Intl.NumberFormat('lt-LT', { style: 'currency', currency: 'EUR' }).format(sum);

function EditableCell({ row, column, getValue, table, type, autoFocus, cell, renderValue }: {
  row: any,
  column: any,
  getValue: any,
  table: any,
  type?: string,
  autoFocus?: boolean,
}) {
  const { form, disabled } = table.options.meta;

  const name = `items[${row.index}].${column.id}`;
  const { onChange, onBlur, ref } = form.register(name, {
    valueAsNumber: type === 'number',
  });

  useEffect(() => {
    if (!autoFocus) return;
    document.querySelector<HTMLInputElement>(`input[name="${name}"]`)?.focus();
  }, []);

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <FormItem>
            <FormControl>
              <Input
                {...field}
                name={name}
                className="text-right"
                type={type ?? "text"}
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={(e) => e.target.select()}
                disabled={disabled}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )
      }
      }
    />
  )
}

export const invoiceItemsColumns: ColumnDef<InvoiceType>[] = [
  {
    accessorKey: "order",
    header: "Nr.",
    cell: ({ row }) => row.index + 1
  },
  {
    accessorKey: "product_id",
    header: "ID",
  },
  {
    accessorKey: "name",
    header: "Pavadinimas",
    cell: ({ row, getValue }) => {
      const { original } = row;
      return (
        <>
          {getValue("name")}
          {original.latin && (
            <div className="italic text-gray-600">
              ({original.latin})
            </div>
          )}
        </>
      )
    }
  },
  {
    accessorKey: "quantity",
    header: "Kiekis",
    cell: (cellParams) => <EditableCell {...cellParams} type="number" autoFocus={true} />
  },
  {
    accessorKey: "unit",
    header: "Vnt.",
    cell: (cellParams) => <EditableCell {...cellParams} />
  },
  {
    accessorKey: "price",
    header: "Kaina",
    cell: (cellParams) => {
      return (
        <div className="whitespace-nowrap flex items-center">
          <EditableCell {...cellParams} type="number" />&nbsp;&euro;
        </div>
      )
    },
  },
  {
    accessorKey: "total",
    header: "Suma",
    cell: ({ row }) => {
      return (
        <div className="text-right">
          {toSumEur(row.original.quantity * row.original.price)}
        </div>
      )
    }
  },
  {
    accessorKey: "actions",
    header: "",
    cell: ({ row, table }) => {
      const { disabled, removeRow } = table.options.meta ?? {};
      return (
        <Button
          disabled={disabled}
          onClick={() => removeRow(row.index)}
          type="button"
        >
          <Trash2 size={16} />
        </Button>
      )
    },
  },
];
