import { zodResolver } from '@hookform/resolvers/zod'
import { useState, useEffect } from 'react'
import { useForm, useFieldArray, set } from 'react-hook-form'
import { useNavigate, useRouteLoaderData } from 'react-router-dom'
import { z } from '@/lib/zod-lt'
import {
  CalendarIcon,
  UnlockKeyholeIcon,
  LockKeyholeIcon,
  Loader2 as LoadingIcon
} from "lucide-react"

import { supabase } from '@/supabaseClient'
import { Button } from '@/components/ui/button'
import { Calendar } from "@/components/ui/calendar"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"
import { cn } from "@/lib/utils"

import { Combobox } from '@/components/Combobox'
import { ClientForm } from '@/components/ClientForm';
import { ProductForm } from '@/components/ProductForm';
// import { getPdfDocument } from '@/utils/getPdfDocument'
import { InvoiceItemsDataTable } from '@/components/tables/InvoiceItemsDataTable'
import { invoiceItemsColumns } from '@/components/tables/invoiceItemsColumns'
import { UPDATE_INVOICE_ROUTE_ID } from '@/router'
import { useStore } from '@/store'
import { InvoiceItemType } from '@/types'

export const CLIENT_ID_FIELD = 'client_id'
export const PRODUCT_FIELD = 'product'
export const ITEMS_FIELD = 'items'
export const DATE_FIELD = 'date'
export const PAYMENT_METHOD_FIELD = 'payment_method'
export const RECEIPT_CODE_FIELD = 'receipt_code'
export const NOTES_FIELD = 'notes'

const PAYMENT_METHODS = [
  { label: 'Grynais', value: 'Grynais' },
  { label: 'Pavedimu', value: 'Pavedimu' },
]

const intl = new Intl.DateTimeFormat('lt-LT', { dateStyle: 'long' })

const invoiceItemSchema = z.object({
  product_id: z.string(),
  name: z.string(),
  latin: z.string().nullable(),
  quantity: z.number().gt(0),
  unit: z.string(),
  price: z.number().gt(0),
});

const formSchema = z.object({
  [CLIENT_ID_FIELD]: z.number({ required_error: 'Pasirinkite klientą.' }),
  [ITEMS_FIELD]: z.array(invoiceItemSchema).min(1, { message: 'Pridėkite bent vieną prekę.' }),
  [DATE_FIELD]: z.date(),
  [PAYMENT_METHOD_FIELD]: z.string(),
  [RECEIPT_CODE_FIELD]: z.string().optional(),
  [NOTES_FIELD]: z.string().optional(),
});

export function InvoiceForm(): JSX.Element {
  const loaderData = useRouteLoaderData(UPDATE_INVOICE_ROUTE_ID);
  const navigate = useNavigate()

  const {
    clients,
    products,
    createInvoice,
    updateInvoice,
    tenant,
    invoices,
    setClients,
    setProducts,
    isCreateInvoiceLoading,
    isUpdateInvoiceLoading,
    removeInvoiceItem,
  } = useStore(s => s);
  const [invoiceItems, setInvoiceItems] = useState<InvoiceItemType[]>(loaderData?.invoice_items ?? []); // Updated type
  const [invoiceId, setInvoiceId] = useState<number | undefined>(loaderData?.id);
  const [invoiceCode, setInvoiceCode] = useState<string | undefined>(loaderData?.code);
  const [isClientsComboboxOpen, setClientsComboboxOpen] = useState<boolean>(false)
  const [isProductsComboboxOpen, setProductsComboboxOpen] = useState<boolean>(false)
  const [isCreateClientDialogOpen, setCreateClientDialogOpen] = useState<boolean>(false);
  const [isLocked, setLocked] = useState<boolean>(!!loaderData?.id);

  // TODO: should be memoized
  const initialDate = loaderData?.date ? new Date(loaderData?.date) : new Date()

  const form = useForm<z.infer<typeof formSchema>>({
    mode: 'all',
    resolver: zodResolver(formSchema),
    defaultValues: {
      [CLIENT_ID_FIELD]: loaderData?.client_id,
      [DATE_FIELD]: initialDate,
      [ITEMS_FIELD]: loaderData?.invoice_items ?? [],
      [PAYMENT_METHOD_FIELD]: loaderData?.payment_method ?? PAYMENT_METHODS[0].value,
      [RECEIPT_CODE_FIELD]: loaderData?.receipt_code ?? '',
      [NOTES_FIELD]: loaderData?.notes ?? '',
    },
  });

  const invoiceCodes = invoices
    .filter((invoice) => !isNaN(invoice.code) && !invoice.discarded)
    .map(({ code }) => Number(code))
  const receiptCodes = invoices
    .filter((invoice) => !isNaN(invoice.receipt_code) && !invoice.discarded)
    .map(({ receipt_code }) => Number(receipt_code))
  const [lastInvoiceNumber] = invoiceCodes.sort((a, b) => b - a);
  const [lastReceiptNumber] = receiptCodes.sort((a, b) => b - a);
  const lastReceiptCode = String(lastReceiptNumber).padStart(6, '0');
  const newReceiptCode = String(lastReceiptNumber + 1).padStart(6, '0');
  const selectedClient = clients?.find(client => client.id === form.getValues(CLIENT_ID_FIELD));
  const selectedClientOption = selectedClient ? { name: selectedClient.name, id: selectedClient.id } : null;
  const payment_method = form.getValues(PAYMENT_METHOD_FIELD);
  const isCash = payment_method === PAYMENT_METHODS[0].value;
  const receiptCode = form.getValues(RECEIPT_CODE_FIELD);
  const receipt_code = isCash && receiptCode ? receiptCode : undefined;
  const client = clients?.find(client => client.id === form.getValues(CLIENT_ID_FIELD));
  const date = intl.format(new Date(form.getValues(DATE_FIELD)));
  const clientName = client?.name.replace(/\s/g, '-').toLowerCase();
  const fullClientName = `${client?.type ? client.type.toLowerCase() : ''}-${clientName}`;

  useEffect(() => {
    if (form.getValues(PAYMENT_METHOD_FIELD) === PAYMENT_METHODS[1].value) return;
    if (form.getValues(RECEIPT_CODE_FIELD) !== '') return;
    if (isNaN(lastReceiptNumber)) return;
    form.setValue(RECEIPT_CODE_FIELD, newReceiptCode);
  }, [form, newReceiptCode]);

  form.watch((val) => {
    setInvoiceItems(val[ITEMS_FIELD]);
  })

  const { append, remove } = useFieldArray({
    control: form.control,
    name: ITEMS_FIELD,
  });

  async function handleRemoveRow(index: number) {
    const items = form.getValues(ITEMS_FIELD);
    if (loaderData) await removeInvoiceItem(items[index].id);
    setInvoiceItems(items.filter((_, i) => i !== index));
    remove(index);
  }

  // format date from 2022-01-01 to 2022.01.01
  function formatPPKDate(date: Date) {
    const intl = new Intl.DateTimeFormat('lt-LT', { dateStyle: 'short' })
    return intl.format(date).replace(/-/g, '.');
  }

  async function onSubmit(formData: z.infer<typeof formSchema>): Promise<void> {
    const newInvoiceCode = String(lastInvoiceNumber + 1).padStart(6, '0');
    // const { invoice_series } = tenant;
    const currentInvoiceCode = loaderData ? loaderData?.code : newInvoiceCode;
    // const receipt_order_number = `PPK: ${invoice_series} Nr. ${receipt_code} ${formatPPKDate(new Date(form.getValues(DATE_FIELD)))}`;
    // const receipt_order_text = `už sodinukus pagal PVM sąskaitą-faktūrą (specifikaciją) Nr. ${invoice_series} ${currentInvoiceCode}`;

    if (!client) throw new Error('no client found');

    if (loaderData?.id) {

      if (!invoiceId) throw new Error('no invoice id found');

      await updateInvoice({
        client_id: client?.id,
        contact_name: client?.name,
        id: invoiceId,
        date: formData[DATE_FIELD].toISOString(),
        invoice_items: invoiceItems,
        payment_method: formData[PAYMENT_METHOD_FIELD],
        receipt_code,
        notes: formData[NOTES_FIELD],
        // receipt_order_number,
        // receipt_order_text,
      });

      // location.reload();

    } else {
      const { id, code } = await createInvoice({
        client_id: client?.id,
        contact_name: client?.name,
        invoice_items: invoiceItems,
        code: currentInvoiceCode,
        date: formData[DATE_FIELD].toISOString(),
        payment_method: formData[PAYMENT_METHOD_FIELD],
        receipt_code,
        notes: formData[NOTES_FIELD],
        // receipt_order_number,
        // receipt_order_text,
      })

      setInvoiceId(id)
      setInvoiceCode(code)
      navigate(`/invoices/${id}`)
    }
  };

  function appendInvoiceItem(product) {
    append({
      product_id: product.id,
      name: product.name,
      latin: product.latin,
      quantity: 1,
      unit: 'vnt.', // TODO: should come from settings and from product
      price: product.price,
    });
  };

  function handleProductSelect({ id }): void {
    const product = products?.find(product => product.id === id)
    if (!product) throw new Error('no product found');
    appendInvoiceItem(product);
  };

  function handleClientCreate(newClient) {
    setClients([newClient, ...clients]);
    setCreateClientDialogOpen(false);
    setClientsComboboxOpen(false);
    form.setValue(CLIENT_ID_FIELD, newClient.id);
    form.trigger(CLIENT_ID_FIELD);
  }

  function handleProductCreate(newProduct) {
    setProducts([...products, newProduct]);
    setProductsComboboxOpen(false);
    appendInvoiceItem(newProduct);
  }

  async function createPdfDocument() {
    const { getPdfDocument } = await import('@/utils/getPdfDocument');

    return getPdfDocument({
      tenant,
      client,
      invoiceCode,
      notes: form.getValues(NOTES_FIELD),
      receiptCode: receipt_code,
      date: new Date(form.getValues(DATE_FIELD)),
      invoiceItems,
    });
  }

  // Legacy upload to the bucket handling (not used anymore, but left for reference)
  function handleUploadButtonClick() {
    createPdfDocument().getBlob(async (blob) => {
      const fileName = `${invoiceCode}.pdf`;
      const bucketName = `tenant-${tenant.id}-invoices`;
      const { data, error } = await supabase.storage.from(bucketName).upload(fileName, blob, { upsert: true })
    });
  }

  // Instead of downloading from the bucket, let's create it on the fly
  async function handleDownloadButtonClick() {
    const pdf = await createPdfDocument();
    const intl = new Intl.DateTimeFormat('lt-LT', { dateStyle: 'short' })
    pdf.download(`${invoiceCode}-${date}-${fullClientName}`);
    // window.print();
    pdf.open();
  }

  function handlePublishButtonClick() {
    // setDraft(false)
  }

  if (!clients || !products) return <></>;

  const clientOptions = clients
    .map(({ name, id }) => ({ name, id }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const productOptions = products
    .map((product) => {
      const properties = [
        product.age,
        product.type,
        product.id,
        `${product.price} €`,
      ].filter(Boolean).join(' | ');

      return {
        name: `${product.name} (${properties})`,
        id: product.id,
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  function handleClientSelect({ id }: { id: number }): void {
    form.setValue(CLIENT_ID_FIELD, id);
    form.trigger(CLIENT_ID_FIELD);
  }

  function handlePaymentMethodSelect({ id }: { id: string }): void {
    form.setValue(PAYMENT_METHOD_FIELD, id);
    form.trigger(PAYMENT_METHOD_FIELD);
  }

  const buttonIdleLabel = loaderData?.id ? 'Atnaujinti' : 'Sukurti';
  const buttonLoadingLabel = loaderData?.id ? 'Atnaujinama...' : 'Kuriama...';
  const isIvoiceLoading = isCreateInvoiceLoading || isUpdateInvoiceLoading;
  const buttonLabel = isIvoiceLoading ? buttonLoadingLabel : buttonIdleLabel;

  const format = (date: Date) => intl.format(new Date(date))

  return (
    <div className="my-8">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid gap-8 grid-cols-2">
            <FormField control={form.control} name={CLIENT_ID_FIELD} render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Klientas</FormLabel>
                <Combobox
                  options={clientOptions}
                  isOpen={isClientsComboboxOpen}
                  onComboboxOpenChange={setClientsComboboxOpen}
                  selectedOption={selectedClientOption}
                  onSelect={handleClientSelect}
                  selectLabel="Pasirinkite klientą"
                  searchLabel="Ieškoti kliento..."
                  notFoundLabel="Klientas nerastas."
                  dialogTitle="Naujas klientas"
                  dialogDescription="Sukurkite naują klientą."
                  isDialogOpen={isCreateClientDialogOpen}
                  onDialogOpenChange={setCreateClientDialogOpen}
                  disabled={isLocked}
                >
                  <ClientForm onClientFormSubmit={handleClientCreate} />
                </Combobox>
                <FormMessage />
              </FormItem>
            )}
            />
            <FormField control={form.control} name={DATE_FIELD} render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Data</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        className={cn("w-full pl-3 text-left font-normal", !field.value && "text-muted-foreground")}
                        disabled={isLocked}
                      >
                        {field.value ? format(field.value) : <span>Pasirinkite datą</span>}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent
                    className="w-auto p-0"
                    align="start"
                    trackingValue={field.value}
                  >
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
            />
          </div>
          <InvoiceItemsDataTable
            columns={invoiceItemsColumns}
            form={form}
            data={invoiceItems}
            disabled={isLocked}
            onRemoveRow={handleRemoveRow}
          >
            <Combobox
              options={productOptions}
              isOpen={isProductsComboboxOpen}
              onComboboxOpenChange={setProductsComboboxOpen}
              selectedOption={null}
              onSelect={handleProductSelect}
              selectLabel="Pridėti prekę"
              searchLabel="Ieškoti prekės..."
              notFoundLabel="Prekė nerasta."
              disabled={isLocked}
            >
              <ProductForm onProductFormSubmit={handleProductCreate} />
            </Combobox>
          </InvoiceItemsDataTable>
          {form.formState.errors[ITEMS_FIELD] && (
            <div className="text-[0.8rem] font-medium text-destructive">
              {form.formState.errors[ITEMS_FIELD].message}
            </div>
          )}
          <div className="grid gap-8 grid-cols-2">
            <div>
              <FormField control={form.control} name={PAYMENT_METHOD_FIELD} render={({ field }) => (
                <FormItem className="flex flex-col mb-4">
                  <FormLabel>Apmokėjimo būdas</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value} disabled={isLocked}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Pasirinkite apmokėjimo būdą" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {PAYMENT_METHODS.map((type) => (
                        <SelectItem key={type.value} value={type.value}>
                          {type.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )} />
              {isCash && (
                <FormField control={form.control} name={RECEIPT_CODE_FIELD} render={({ field }) => (
                  <FormItem>
                    <FormLabel className="block">Kasos Orderio Numeris*</FormLabel>
                    <FormControl>
                      <Input {...field} disabled={isLocked} />
                    </FormControl>
                    <FormDescription>
                      Paskutinis išrašytas kasos orderio numeris sistemoje: <b>{lastReceiptCode}</b>
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )} />
              )}
            </div>
            <FormField control={form.control} name={NOTES_FIELD} render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Pastabos</FormLabel>
                <FormControl>
                  <Textarea {...field} disabled={isLocked} rows={6} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )} />
          </div>
          <div className="flex">
            <Button
              className="mr-4"
              type="submit"
              disabled={isIvoiceLoading || isLocked}
              onClick={handlePublishButtonClick}
            >
              {buttonLabel}
            </Button>
            {loaderData?.id && !loaderData.discarded && (
              <TooltipProvider delayDuration={0}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      className="mr-4"
                      type="button"
                      variant={isLocked ? 'secondary' : 'outline'}
                      onClick={() => setLocked(!isLocked)}
                    >
                      {isLocked ? <LockKeyholeIcon className="h-4 w-4" /> : <UnlockKeyholeIcon className="h-4 w-4" />}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="bottom">
                    <p>Spustelkite šią ikoną, kad galėtumėte redaguoti jau sukurtą sąskaitą</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        </form>
      </Form>
      {!!loaderData && (
        <Button className="mt-4" onClick={handleDownloadButtonClick}>Parsisiųsti</Button>
      )}
    </div>
  )
}
