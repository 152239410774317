import { LoaderFunctionArgs } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import { App } from '@/App'
import { Invoices } from '@/pages/Invoices';
import { Clients } from '@/pages/Clients';
import { Products } from '@/pages/Products';
import { CreateInvoice } from '@/pages/CreateInvoice';
import { UpdateInvoice } from '@/pages/UpdateInvoice';
import { UpdateClient } from '@/pages/UpdateClient';
import { UpdateProduct } from '@/pages/UpdateProduct';
import { Settings } from '@/pages/Settings';
import { Reports } from '@/pages/Reports';
import { supabase } from '@/supabaseClient';
import {
  fetchInitialData,
} from '@/api';

export type UpdateInvoiceParams = {
  id: string
}

export type UpdateClientParams = {
  id: string
}

export const ROOT_ROUTE_ID = 'root';
export const INVOICES_ROUTE_ID = 'invoices';
export const CREATE_INVOICE_ROUTE_ID = 'create';
export const PRODUCTS_ROUTE_ID = 'products';
export const CLIENTS_ROUTE_ID = 'clients';
export const SETTINGS_ROUTE_ID = 'settings';
export const UPDATE_CLIENT_ROUTE_ID = 'update-client';
export const UPDATE_INVOICE_ROUTE_ID = 'update-invoice';
export const UPDATE_PRODUCT_ROUTE_ID = 'update-product';
export const REPORTS_ROUTE_ID = 'reports';

export const routes = [
  {
    path: '/',
    id: ROOT_ROUTE_ID,
    element: <App />,
    loader: async () => {
      const { tenant, invoices, clients, products, user } = await fetchInitialData();
      return {
        tenant,
        invoices,
        clients,
        products,
        user,
      };
    },
    children: [
      {
        index: true,
        id: INVOICES_ROUTE_ID,
        element: <Invoices />,
      },
      {
        path: 'create',
        id: CREATE_INVOICE_ROUTE_ID,
        element: <CreateInvoice />
      },
      {
        path: 'products',
        id: PRODUCTS_ROUTE_ID,
        element: <Products />
      },
      {
        path: 'clients',
        id: CLIENTS_ROUTE_ID,
        element: <Clients />
      },
      {
        path: 'settings',
        id: SETTINGS_ROUTE_ID,
        element: <Settings />
      },
      {
        path: 'reports',
        id: REPORTS_ROUTE_ID,
        element: <Reports />,
        loader: async ({ params }: LoaderFunctionArgs<UpdateClientParams>) => {
          // const { invoices, clients } = await fetchInitialData();
          // return {
          //   invoices,
          //   clients,
          // };
          return null;
        }
      },
      {
        path: 'clients/:id',
        id: UPDATE_CLIENT_ROUTE_ID,
        element: <UpdateClient />,
        loader: async ({ params }: LoaderFunctionArgs<UpdateClientParams>) => {
          const { data } = await supabase
            .from('clients')
            .select('*')
            .eq('id', params.id)
            .single()
          return data
        }
      },
      {
        path: 'invoices/:id',
        id: UPDATE_INVOICE_ROUTE_ID,
        element: <UpdateInvoice />,
        loader: async ({ params }: LoaderFunctionArgs<UpdateInvoiceParams>) => {
          const { data } = await supabase
            .from('invoices')
            .select('*,invoice_items(*),clients(*)')
            .eq('id', params.id)
            .single()
          return data
        }
      }, {
        path: 'products/:id',
        id: UPDATE_PRODUCT_ROUTE_ID,
        element: <UpdateProduct />,
        loader: async ({ params }: LoaderFunctionArgs<UpdateInvoiceParams>) => {
          const { data } = await supabase
            .from('products')
            .select('*')
            .eq('id', params.id)
            .single()
          return data
        },
      }
    ]
  }
]

export const router = createBrowserRouter(routes);
