import { productsColumns } from '@/components/tables/productsColumns';
import { ProductsDataTable } from '@/components/tables/ProductsDataTable';
import { useStore } from '@/store';

export function Products(): JSX.Element {
  const { products } = useStore((state) => state)

  if (!products) return <div>Loading...</div>

  return (
    <>
      <h1 className="text-2xl my-4 font-bold">Produktai:</h1>
      <ProductsDataTable data={products} columns={productsColumns} />
    </>
  )
}
