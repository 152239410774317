import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import type { Session } from '@supabase/supabase-js';
import { useState, useEffect, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import '@/assets/index.css';
import { routes } from '@/router';
import { supabase } from '@/supabaseClient';

Sentry.init({
  dsn: "https://88c14122153ce5294d74b5986a0dc93d@o75771.ingest.us.sentry.io/4507173509267456",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/dekobase\.cc\/api/,
    /^https:\/\/tickecdzfrelikxshmux\.supabase\.co\/rest/,
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById('root')
const LOCALIZATION_VARIABLES = {
  sign_up: {
    email_label: "El. paštas",
    password_label: "Slaptažodis",
    email_input_placeholder: "vardas@gmail.com",
    password_input_placeholder: "Slaptažodis",
    button_label: "Registruotis",
    loading_button_label: "Registruojama...",
    social_provider_text: "Prisijungti su {{provider}}",
    link_text: "Neturite paskyros? Registruokitės",
    confirmation_text: "Patvirtinkite savo el. pašto adresą, kad užbaigtumėte registraciją",
  },
  sign_in: {
    email_label: "El. paštas",
    email_input_placeholder: "vardas@gmail.com",
    password_label: "Slaptažodis",
    password_input_placeholder: "Slaptažodis",
    button_label: "Prisijungti",
    loading_button_label: "Prisijungiama...",
    social_provider_text: "Prisijungti su {{provider}}",
    link_text: "Jau turite paskyrą? Prisijunkite",
  },
  forgotten_password: {
    email_label: "El. paštas",
    email_input_placeholder: "vardas@gmail.com",
    button_label: "Atstatyti slaptažodį",
    loading_button_label: "Siunčiamos instrukcijos...",
    link_text: "Pamiršote slaptažodį?",
    confirmation_text: "Patikrinkite savo el. paštą, kad atstatytumėte slaptažodį",
  },
}

function Main(): JSX.Element {
  const [session, setSession] = useState<Session | null>(null)

  useEffect(() => {
    // TODO: this should also handle failed login:
    supabase.auth.getSession().then(({ data }) => setSession(data.session));
    supabase.auth.onAuthStateChange((_, session) => setSession(session));
  }, [])

  if (session) {
    const router = createBrowserRouter(routes);
    return <RouterProvider router={router} />
  }

  return (
    // TODO: this should be replace by custom login page:
    <Auth
      supabaseClient={supabase}
      appearance={{
        theme: ThemeSupa,
        className: {
          container: 'p-8 h-full w-full flex flex-col justify-center mx-auto max-w-[400px] w-full',
          button: 'bg-[--colors-brand] text-white hover:bg-[--colors-brand-dark]',
        }
      }}
      localization={{
        variables: LOCALIZATION_VARIABLES
      }}
    />
  )
}

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <StrictMode>
      <Main />
    </StrictMode>
  )
}
