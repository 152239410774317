import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  // getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { useState } from "react"

import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"

import { useStore } from '@/store'
import { Database } from '@/types/supabase'

export type InvoiceType = Database['public']['Tables']['invoices']['Row']

interface InvoicesDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[],
  disabled?: boolean,
}

const DEFAULT_SORT = { id: 'code', desc: true };

const COLUMN_LABELS = {
  code: "Kodas",
  contact_name: "Klientas",
  date: "Data",
  due_date: "Apmokėjimo data",
  sum: "Suma",
  status: "Būsena",
  discarded: "Sugadinta",
};

const SHOW_DISCARDED_FILTER = {
  id: 'discarded',
  value: false,
};

export function InvoicesDataTable<TData, TValue>({
  columns,
  data,
}: InvoicesDataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([DEFAULT_SORT])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([SHOW_DISCARDED_FILTER]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({ discarded: false, });
  const { openDialog, removeInvoice, } = useStore(s => s)

  const showDiscarded = !columnFilters.some(({ id }) => id === 'discarded');

  function handleShowDiscardedChange() {
    setColumnFilters((prev) => {
      if (prev.some(f => f.id === 'discarded')) {
        return prev.filter(f => f.id !== 'discarded');
      }
      return [SHOW_DISCARDED_FILTER, ...prev];
    });
  };

  function onRemoveRow(id: number) {
    openDialog({
      title: 'Ištrinti sąskaitą',
      message: 'Ar tikrai norite ištrinti šią sąskaitą?',
      confirmText: 'Ištrinti',
      onConfirm: async () => await removeInvoice(id),
    });
  }
  // const [rowSelection, setRowSelection] = useState({})
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    // getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    // onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      // rowSelection,
    },
    meta: {
      removeRow: (rowIndex: number) => onRemoveRow(rowIndex),
    },
  })

  return (
    <div>
      <div className="flex items-center justify-between py-4">
        <Input
          placeholder="Ieškoti tarp klientų..."
          value={(table.getColumn("contact_name")?.getFilterValue() as string) ?? ""}
          onChange={e => table.getColumn("contact_name")?.setFilterValue(e.target.value)}
          className="max-w-sm"
        />
        <div className="flex items-center">
          <div className="flex items-center px-2">
            <Checkbox id="show-discarded"
              checked={showDiscarded}
              onCheckedChange={handleShowDiscardedChange} />
            <label
              htmlFor="show-discarded"
              className="text-sm px-2 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Rodyti ištrintas
            </label>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                Stulpeliai
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        column.toggleVisibility(!!value)
                      }
                    >
                      {COLUMN_LABELS[column.id] ?? column.id}
                    </DropdownMenuCheckboxItem>
                  )
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                const { discarded } = row.original;
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={discarded ? "opacity-50 line-through" : ""}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {/* <div className="flex-1 text-sm text-muted-foreground">
        {table.getFilteredSelectedRowModel().rows.length} of{" "}
        {table.getFilteredRowModel().rows.length} row(s) selected.
      </div> */}
    </div>
  )
}
