import { ColumnDef } from "@tanstack/react-table"
import { ArrowUpDown, MoreHorizontal } from "lucide-react"
import { ReactNode } from 'react';

import { Database } from '@/types/supabase'
import { Button } from '@/components/ui/button'
import { Checkbox } from "@/components/ui/checkbox"
import { Link } from "react-router-dom";

export type ProductType = Database['public']['Tables']['products']['Row']

const intl = new Intl.DateTimeFormat('lt-LT', { dateStyle: 'short', timeStyle: 'short' })
const toDate = (date: string) => date ? intl.format(new Date(date)) : '';
const toSumEur = (sum: number) => new Intl.NumberFormat('lt-LT', { style: 'currency', currency: 'EUR' }).format(sum);

interface SortableHeaderProps {
  column: {
    toggleSorting: (value: boolean) => void;
    getIsSorted: () => string | undefined;
  };
  children?: ReactNode;
}

const SortableHeader = ({ column, children }: SortableHeaderProps): JSX.Element => {
  const isSorted = column.getIsSorted() == "asc" ? true : false;
  return (
    <Button
      variant="ghost"
      className="p-0"
      onClick={() => column.toggleSorting(isSorted)}
    >
      {children}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  )
}

export const productsColumns: ColumnDef<ProductType>[] = [{
  id: "select",
  header: ({ table }) => {
    return (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Pasirinkite visas eilutes"
      />
    )
  },
  cell: ({ row }) => (
    <Checkbox
      checked={row.getIsSelected()}
      onCheckedChange={(value) => row.toggleSelected(!!value)}
      aria-label="Pasirinkite eilutę"
    />
  ),
  enableSorting: false,
  enableHiding: false,
},
{
  accessorKey: "id",
  header: ({ column }) => <SortableHeader column={column}>Kodas</SortableHeader>,
  cell: ({ row }) => {
    return (
      <Link
        to={`/products/${row.original.id}`}
        className="whitespace-nowrap font-bold hover:underline"
      >
        {row.getValue("id")}
      </Link>
    )
  },
},
{
  accessorKey: "name",
  header: ({ column }) => <SortableHeader column={column}>Pavadinimas</SortableHeader>,
  cell: ({ row }) => {
    return (
      <div className="">
        <div className="whitespace-nowrap">
          {row.getValue("name")}
        </div>
        {row.original.latin && (
          <span className="text-gray-500 italic"> ({row.original.latin})</span>
        )}
      </div>
    )
  },
},
{
  accessorKey: "updated_at",
  header: ({ column }) => <SortableHeader column={column}>Atnaujinta</SortableHeader>,
  cell: ({ row }) => {
    return (
      <div className="whitespace-nowrap">
        {toDate(row.getValue("updated_at"))}
      </div>
    )
  },
},
{
  accessorKey: "price",
  header: ({ column }) => <SortableHeader column={column}>Kaina</SortableHeader>,
  cell: ({ row }) => {
    return (
      <div className="whitespace-nowrap">
        {toSumEur(row.getValue("price"))}
      </div>
    )
  },
},
];
