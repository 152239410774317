import { create } from 'zustand'
import { toast } from "sonner";

import { supabase } from '@/supabaseClient'
import { InvoiceItemType, DialogType } from '@/types'
import { StoreType } from '@/store.types'
import { symbol } from 'zod';

export const useStore = create<StoreType>((set, getStore) => ({
  products: [],
  tenant: null,
  invoices: [],
  clients: [],
  user: null,
  isCreateInvoiceLoading: false,
  hasCreateInvoiceError: false,
  createInvoiceError: null,
  isUpdateInvoiceLoading: false,
  hasUpdateInvoiceError: false,
  updateInvoiceError: null,
  isUpsertClientLoading: false,
  hasUpsertClientError: false,
  upsertClientError: null,
  isRemoveInvoiceLoading: false,
  hasRemoveInvoiceError: false,
  removeInvoiceError: null,
  isUpdateTenantLoading: false,
  hasUpdateTenantError: false,
  updateTenantError: null,
  isUpsertProductLoading: false,
  hasUpsertProductError: false,
  upsertProductError: null,
  dialogs: [],
  reset: async () => {
    try {
      const { error } = await supabase.from('invoice_items').delete().neq('id', 0);
      if (error) {
        toast.error(error.message);
        throw error;
      }

      toast.success('Invoice Items deletion complete');
    } catch (error) {
      toast.error(error.toString());
      throw error;
    }

    try {
      const { error } = await supabase.from('invoices').delete().neq('id', 0);
      if (error) {
        toast.error(error.message);
        throw error;
      }

      toast.success('Invoices deletion complete');
    } catch (error) {
      toast.error(error.toString());
      throw error;
    }

    try {
      const { error } = await supabase.from('clients').delete().neq('id', 0);
      if (error) {
        toast.error(error.message);
        throw error;
      }

      toast.success('Clients deletion complete');
    } catch (error) {
      toast.error(error.toString());
      throw error;
    }

    try {
      const { error } = await supabase.from('products').delete().neq('id', 0);
      if (error) {
        toast.error(error.message);
        throw error;
      }

      toast.success('Products deletion complete');
    } catch (error) {
      toast.error(error.toString());
      throw error;
    }

    try {
      const products = await import('@/data/products.json');
      const productsResponse = await supabase.from('products').upsert(products.default);
      console.debug('productsResponse', productsResponse);
      set({ products: products.default });
      toast.success('Legacy Products inserted');
    } catch (error) {
      toast.error(error.message);
      throw error;
    }

    try {
      const clients = await import('@/data/clients.json');
      const clientsResponse = await supabase.from('clients').upsert(clients.default);
      console.debug('clientsResponse', clientsResponse);
      set({ clients: clients.default });
      toast.success('Legacy Clients inserted');
    } catch (error) {
      toast.error(error.message);
      throw error;
    }

    try {
      const invoices = await import('@/data/invoices.json');
      const invoicesResponse = await supabase.from('invoices').upsert(invoices.default);
      console.debug('invoicesResponse', invoicesResponse);
      set({ invoices: invoices.default });
      toast.success('Legacy Invoices inserted');
    } catch (error) {
      toast.error(error.message);
      throw error;
    }

    try {
      const invoice_items = await import('@/data/invoice_items.json');
      const invoiceItemsResponse = await supabase.from('invoice_items').upsert(invoice_items.default);
      console.debug('invoiceItemsResponse', invoiceItemsResponse);
      toast.success('Legacy Invoice Items inserted');
    } catch (error) {
      toast.error(error.message);
      throw error;
    }

    toast.success('Data reset complete');
  },
  setInvoices: (invoices) => set({ invoices }),
  setClients: (clients) => set({ clients }),
  setProducts: (products) => set({ products }),
  setInitialData: ({ invoices, clients, products, tenant, user }) => set({ invoices, clients, products, tenant, user }),
  createInvoice: async ({ invoice_items, ...payload }) => {
    set({
      isCreateInvoiceLoading: true,
      hasCreateInvoiceError: false,
      createInvoiceError: null
    });
    const { invoices, tenant } = getStore();
    const total = invoice_items.reduce((acc, item) => acc + (item.price * item.quantity), 0);
    const lastInvoiceId = Math.max(...invoices.map((invoice) => invoice.id), 0);

    try {
      const { error: invoicesError, data: invoicesData } = await supabase
        .from('invoices')
        .insert({
          ...payload,
          total,
          id: lastInvoiceId + 1,
          series: tenant?.invoice_series,
        })
        .select()
        .single();

      if (invoicesError) {
        set({ hasCreateInvoiceError: true, createInvoiceError: invoicesError.message });
        toast.error(`Kuriant sąskaitą įvyko klaida. Bandykite dar kartą.\n${invoicesError.message}`);
        throw invoicesError;
      }

      set({ invoices: [...(invoices ?? []), invoicesData] });

      // Attach created invoice id to each of invoice items
      const items = invoice_items.map((item: InvoiceItemType) => ({
        ...item,
        invoice_id: invoicesData.id,
      }));

      const { error: itemsError } = await supabase.from('invoice_items').insert(items);

      if (itemsError) {
        set({ hasCreateInvoiceError: true, createInvoiceError: itemsError.message });
        toast.error(`Kuriant sąskaitą įvyko klaida. Bandykite dar kartą.\n${itemsError.message}`);
        throw itemsError;
      }

      toast.success('Sąskaita sėkmingai sukurta');
      return {
        id: invoicesData.id,
        code: invoicesData.code,
      }
    } catch (error: Error | unknown) {
      const errorMessage = error?.toString();
      set({ hasCreateInvoiceError: true, createInvoiceError: errorMessage });
      toast.error(`Kuriant sąskaitą įvyko klaida. Bandykite dar kartą.\n${errorMessage}`);
      throw error;
    } finally {
      set({ isCreateInvoiceLoading: false });
    }
  },
  updateInvoice: async ({ invoice_items, ...payload }) => {
    set({ isUpdateInvoiceLoading: true, hasUpdateInvoiceError: false, updateInvoiceError: null });
    const total = invoice_items.reduce((acc, item) => acc + (item.price * item.quantity), 0);

    try {
      const {
        error: invoicesError,
        data: invoicesData
      } = await supabase
        .from('invoices')
        .upsert({ ...payload, total })
        .select()
        .single();

      if (invoicesError) {
        set({ hasUpdateInvoiceError: true, updateInvoiceError: invoicesError.message });
        toast.error(`Atnaujinant sąskaitą įvyko klaida. Bandykite dar kartą.\n${invoicesError.message}`);
        throw invoicesError;
      }

      const itemsWithInvoiceId = invoice_items.map((item: InvoiceItemType) => ({
        ...item,
        invoice_id: invoicesData.id,
      }));

      const newInvoiceItems = itemsWithInvoiceId.filter((item: InvoiceItemType) => !item.id);
      const existingInvoiceItems = itemsWithInvoiceId.filter((item: InvoiceItemType) => !!item.id);

      const { error: itemsInsertError } = await supabase.from('invoice_items').insert(newInvoiceItems);
      const { error: itemsUpdateError } = await supabase.from('invoice_items').upsert(existingInvoiceItems);

      if (itemsInsertError) {
        set({ hasUpdateInvoiceError: true, updateInvoiceError: itemsInsertError.message });
        toast.error(`Atnaujinant sąskaitą įvyko klaida. Bandykite dar kartą.\n${itemsInsertError.message}`);
        throw itemsInsertError
      }

      if (itemsUpdateError) {
        set({ hasUpdateInvoiceError: true, updateInvoiceError: itemsUpdateError.message });
        toast.error(`Atnaujinant sąskaitą įvyko klaida. Bandykite dar kartą.\n${itemsUpdateError.message}`);
        throw itemsUpdateError
      }

      toast.success('Sąskaita sėkmingai atnaujinta');

    } catch (error: Error | unknown) {
      const errorMessage = error?.toString();
      set({ hasUpdateInvoiceError: true, updateInvoiceError: errorMessage });
      toast.error(`Atnaujinant sąskaitą įvyko klaida. Bandykite dar kartą.\n${errorMessage}`);
      throw error
    } finally {
      set({ isUpdateInvoiceLoading: false })
    }
  },
  hardRemoveInvoice: async (id) => {
    set({ isRemoveInvoiceLoading: true, hasRemoveInvoiceError: false, removeInvoiceError: null })
    const { invoices } = getStore();

    try {
      const { error } = await supabase.from('invoice_items').delete().eq('invoice_id', id)

      if (error) {
        set({ hasRemoveInvoiceError: true, removeInvoiceError: error.message })
        toast.error(`Trinant sąskaitą įvyko klaida. Bandykite dar kartą.\n${error?.message}`);
        throw error
      }

    } catch (error: Error | unknown) {
      const errorMessage = error?.toString();
      set({ hasRemoveInvoiceError: true, removeInvoiceError: errorMessage })
      toast.error(`Trinant sąskaitą įvyko klaida. Bandykite dar kartą.\n${errorMessage}`);
      throw error
    }
    try {
      const { error } = await supabase.from('invoices').delete().eq('id', id)

      if (error) {
        set({ hasRemoveInvoiceError: true, removeInvoiceError: error.message });
        toast.error(`Trinant sąskaitą įvyko klaida. Bandykite dar kartą.\n${error?.message}`);
        throw error
      }

      set({ invoices: invoices.filter((invoice) => invoice.id !== id) });
      toast.success('Sąskaita sėkmingai pašalinta');
    } catch (error: Error | unknown) {
      const errorMessage = error?.toString();
      set({ hasRemoveInvoiceError: true, removeInvoiceError: errorMessage });
      toast.error(`Trinant sąskaitą įvyko klaida. Bandykite dar kartą.\n${errorMessage}`);
      throw error
    } finally {
      set({ isRemoveInvoiceLoading: false });
    }
  },
  removeInvoice: async (id) => {
    set({ isRemoveInvoiceLoading: true, hasRemoveInvoiceError: false, removeInvoiceError: null })
    const { invoices } = getStore();

    try {
      const { error } = await supabase.from('invoices').update({ discarded: true }).eq('id', id)

      if (error) {
        set({ hasRemoveInvoiceError: true, removeInvoiceError: error.message })
        toast.error(`Trinant sąskaitą įvyko klaida. Bandykite dar kartą.\n${error?.message}`);
        throw error
      }

      set({ invoices: invoices.map((invoice) => invoice.id === id ? { ...invoice, discarded: true } : invoice) });
      toast.success('Sąskaita sėkmingai pašalinta');
    } catch (error: Error | unknown) {
      const errorMessage = error?.toString();
      set({ hasRemoveInvoiceError: true, removeInvoiceError: errorMessage });
      toast.error(`Trinant sąskaitą įvyko klaida. Bandykite dar kartą.\n${errorMessage}`);
      throw error
    } finally {
      set({ isRemoveInvoiceLoading: false });
    }
  },
  removeInvoiceItem: async (id) => {
    set({ isRemoveInvoiceLoading: true, hasRemoveInvoiceError: false, removeInvoiceError: null })
    // const { invoices } = getStore();

    try {
      const { error } = await supabase.from('invoice_items').delete().eq('id', id)

      if (error) {
        set({ hasRemoveInvoiceError: true, removeInvoiceError: error.message })
        toast.error(`Trinant sąskaitos prekę įvyko klaida. Bandykite dar kartą.\n${error?.message}`);
        throw error
      }

      toast.success('Sąskaitos prekė sėkmingai pašalinta');
    } catch (error: Error | unknown) {
      const errorMessage = error?.toString();
      set({ hasRemoveInvoiceError: true, removeInvoiceError: errorMessage });
      toast.error(`Trinant sąskaitos prekę įvyko klaida. Bandykite dar kartą.\n${errorMessage}`);
      throw error
    } finally {
      set({ isRemoveInvoiceLoading: false });
    }
  },
  updateTenant: async (payload) => {
    set({ isUpdateTenantLoading: true, hasUpdateTenantError: false, updateTenantError: null });
    const { tenant } = getStore();

    try {
      const { error, data } = await supabase
        .from('tenants')
        .update(payload)
        .eq('id', tenant.id)
        .select()
        .single()

      if (error) {
        set({ hasUpdateTenantError: true, updateTenantError: error.message });
        toast.error(`Atnaujinant įmonės duomenis įvyko klaida. Bandykite dar kartą.\n${error.message}`);
        throw error
      }

      toast.success('Įmonės duomenys sėkmingai atnaujinti');
      return data

    } catch (error: Error | unknown) {
      const errorMessage = error?.toString();

      set({ hasUpdateTenantError: true, updateTenantError: errorMessage });
      toast.error(`Atnaujinant įmonės duomenis įvyko klaida. Bandykite dar kartą.\n${errorMessage}`);
      throw error

    } finally {
      set({ isUpdateTenantLoading: false })
    }
  },
  upsertClient: async (payload) => {
    set({ isUpsertClientLoading: true, hasUpsertClientError: false, upsertClientError: null })
    try {
      const { error, data } = await supabase
        .from('clients')
        .upsert(payload)
        .select()
        .single()

      if (error) {
        set({ hasUpsertClientError: true, upsertClientError: error.message });
        throw error
      }

      return data

    } catch (error: Error | unknown) {
      const errorMessage = error?.toString();

      set({ hasUpsertClientError: true, upsertClientError: errorMessage })
      throw error

    } finally {
      set({ isUpsertClientLoading: false })
    }
  },
  upsertProduct: async (payload) => {
    const lastProductId = getStore().products.reduce((acc, product) => {
      const numericId = +product.id;
      if (isNaN(numericId)) return acc;
      return Math.max(acc, numericId);
    }, 0);

    
    try {
      payload.id = payload.id ?? (lastProductId + 1).toString();
      const { error, data } = await supabase
        .from('products')
        .upsert(payload)
        .select()
        .single()
        
      if (error) {
        set({ hasUpsertProductError: true, upsertProductError: error.message });
        toast.error(`Kuriant prekę įvyko klaida. Bandykite dar kartą.\n${error.message}`);
        throw error;
      }

      toast.success('Prekė sėkmingai išsaugota');
      return data
    } catch (error: Error | unknown) {
      const errorMessage = error?.toString();
      set({ hasUpsertProductError: true, upsertProductError: errorMessage });
      toast.error(`Kuriant prekę įvyko klaida. Bandykite dar kartą.\n${errorMessage}`);
      throw error;
    } finally {
      set({ isUpsertProductLoading: false });
    }
  },
  openDialog: (dialog: DialogType) => set((state) => {
    return {
      dialogs: [
        ...state.dialogs,
        {
          ...dialog,
          time: new Date(),
        }
      ]
    }
  }),
  closeDialog: (time: Date) => set((state) => {
    return {
      dialogs: state.dialogs.filter((dialog) => dialog.time !== time)
    }
  }),
}));