import { supabase } from '@/supabaseClient';
import { INVOICES_COLUMNS, CLIENTS_COLUMNS, PRODUCTS_COLUMNS } from '@/constants';

export async function fetchTenant() {
  return await supabase.from('tenants').select('*').single();
}

export async function fetchInvoices() {
  return await supabase.from('invoices').select(INVOICES_COLUMNS.join(',')).order('date', { ascending: false })
}

export async function fetchRecentInvoices() {
  return await supabase.from('invoices').select(INVOICES_COLUMNS.join(',')).gte('date', `${new Date().getFullYear() - 1}-01-01`);
}

export async function fetchUser() {
  return await supabase.auth.getUser();
}

export async function fetchAllInvoices() {
  const LIMIT = 1000;
  const { count } = await supabase.from('invoices').select('*', {
    count: 'exact',
    head: true,
  });
  const requetsCount = count ? Math.ceil(count / LIMIT) : 0;
  const requests = [...Array(requetsCount)].map((_, i) => {
    const rangeStart = i * LIMIT;
    const rangeEnd = rangeStart + LIMIT - 1;
    return supabase.from('invoices').select(INVOICES_COLUMNS.join(',')).range(rangeStart, rangeEnd);
  });
  const responses = await Promise.all(requests);
  const data = responses.reduce((acc, { data, error }) => {
    if (error) acc.error = error;
    if (!data) return acc;

    return {
      data: [...acc.data, ...data],
      error: acc.error,
    };
  }, {
    data: [],
    error: null,
  });
  return data;
}

export async function fetchClients() {
  return await supabase.from('clients').select(CLIENTS_COLUMNS.join(','));
}

export async function fetchProducts() {
  return await supabase.from('products').select(PRODUCTS_COLUMNS.join(','));
}

export async function fetchInvoice(id: string) {
  return await supabase.from('invoices').select('*').eq('id', id).single();
}

export async function fetchInitialData() {
  const [tenant, invoices, clients, products, user] = await Promise.all([
    fetchTenant(),
    fetchRecentInvoices(),
    fetchClients(),
    fetchProducts(),
    fetchUser(),
  ]);

  [tenant, invoices, clients, products, user].forEach(({ error }) => {
    if (error) console.error(error.message);
  });

  return {
    tenant: tenant.data,
    invoices: invoices.data,
    clients: clients.data,
    products: products.data,
    user: user.data.user,
  };
}