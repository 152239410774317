export const INVOICES_COLUMNS = [
  "id",
  "created_at",
  "updated_at",
  "contact_name",
  "total",
  "payment_method",
  "code", // this is different from the id!!!
  "date",
  "client_id",
  "discarded",
  "receipt_code",
  "notes",
  // "tenant_id",
  // "series",
  // "recipient",
  // "carrier",
  // "discount",
  // "vat",
  // "discard",
  // "load_id",
  // "load_series",
  // "damaged",
  // "receipt_order_number",
  // "receipt_order_text",
  // "receipt_series",
  // "packaging_location",
  // "packaging_date",
  // "packaging_time",
  // "unload_location",
  // "unload_date",
  // "unload_time",
  // "vehicle",
  // "license_plate",
  // "driver",
];

export const CLIENTS_COLUMNS = [
  "id",
  "created_at",
  "updated_at",
  "name",
  "code",
  "phone",
  "address",
  "type",
  "contact_name",
  "vat",
  "contract_id",
  "tenant_id",
];

export const PRODUCTS_COLUMNS = [
  "id",
  "created_at",
  "updated_at",
  "name",
  "latin",
  "type",
  "age",
  "stock",
  "price",
  // "container",
  // "height",
  // "kn_quantity",
  // "price_vat",
  // "tenant_id",
];

export const CLIENT_TYPES = [
  {
    label: 'Akcinė bendrovė (AB)',
    value: 'AB'
  },
  {
    label: 'Biudžetinė įmonė (BĮ)',
    value: 'BĮ'
  },
  {
    label: 'Daugiabučių Namų Savininkų Bendrija (DNSB)',
    value: 'DNSB'
  },
  {
    label: 'Individuali įmonė (IĮ)',
    value: 'IĮ'
  },
  {
    label: 'Individuali veikla (IV)',
    value: 'IV'
  },
  {
    label: 'Mažoji bendrija (MB)',
    value: 'MB'
  },
  {
    label: 'Socialinė įmonė (SĮ)',
    value: 'SĮ'
  },
  {
    label: 'Uždaroji akcinė bendrovė (UAB)',
    value: 'UAB'
  },
  {
    label: 'Valstybinė įmonė (VĮ)',
    value: 'VĮ',
  },
  {
    label: 'Visuomeninė organizacija (VO)',
    value: 'VO'
  },
  {
    label: 'Viešoji įstaiga (VšĮ)',
    value: 'VšĮ'
  },
  {
    label: 'Žemės ūkio bendrovė (ŽŪB)',
    value: 'ŽŪB'
  },
  {
    label: 'Žemės ūkio kooperatinė bendrovė (ŽŪKB)',
    value: 'ŽŪKB'
  },
  {
    label: 'Ūkininkas (Ūkin.)',
    value: 'Ūkin.'
  }
];

const PAYMENT_TYPES = {
  CASH: 'Grynaisiais',
  TRANSFER: 'Pavedimu',
};