import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { z } from '@/lib/zod-lt'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"

import { useStore } from '@/store'

export const NAME_FIELD = 'name';
export const TEL_FIELD = 'tel';
export const EMAIL_FIELD = 'email';
export const ADDRESS_FIELD = 'address';
export const CODE_FIELD = 'code';
export const VAT_FIELD = 'vat';
export const BANK_ACCOUNT_FIELD = 'bank_account';
export const BANK_CODE_FIELD = 'bank_code';
export const BANK_NAME_FIELD = 'bank_name';
export const CERTIFICATE_CODE_FIELD = 'certificate_code';
export const FITOSANITARIAN_CODE_FIELD = 'fitosanitarian_code';
export const INVOICE_SERIES_FIELD = 'invoice_series';
export const INVOICE_SIGNATORY_FIELD = 'invoice_signatory';
export const INVOICE_TITLE_FIELD = 'invoice_title';

const intl = new Intl.DateTimeFormat('lt-LT', { dateStyle: 'long' })

const formSchema = z.object({
  [NAME_FIELD]: z.string(),
  [TEL_FIELD]: z.string(),
  [VAT_FIELD]: z.string(),
  [ADDRESS_FIELD]: z.string(),
  [BANK_ACCOUNT_FIELD]: z.string(),
  [BANK_CODE_FIELD]: z.string(),
  [BANK_NAME_FIELD]: z.string(),
  [CERTIFICATE_CODE_FIELD]: z.string(),
  [CODE_FIELD]: z.string(),
  [EMAIL_FIELD]: z.string(),
  [FITOSANITARIAN_CODE_FIELD]: z.string(),
  [INVOICE_SERIES_FIELD]: z.string(),
  [INVOICE_SIGNATORY_FIELD]: z.string(),
  [INVOICE_TITLE_FIELD]: z.string(),
});

export function Settings(): JSX.Element {
  const {
    reset,
    tenant,
    updateTenant,
    openDialog,
    user,
  } = useStore(s => s);
  console.log({ user })

  const defaultValues = {
    [NAME_FIELD]: tenant?.name || '',
    [TEL_FIELD]: tenant?.tel || '',
    [EMAIL_FIELD]: tenant?.email || '',
    [ADDRESS_FIELD]: tenant?.address || '',
    [CODE_FIELD]: tenant?.code || '',
    [VAT_FIELD]: tenant?.vat || '',
    [BANK_ACCOUNT_FIELD]: tenant?.bank_account || '',
    [BANK_CODE_FIELD]: tenant?.bank_code || '',
    [BANK_NAME_FIELD]: tenant?.bank_name || '',
    [CERTIFICATE_CODE_FIELD]: tenant?.certificate_code || '',
    [FITOSANITARIAN_CODE_FIELD]: tenant?.fitosanitarian_code || '',
    [INVOICE_SERIES_FIELD]: tenant?.invoice_series || '',
    [INVOICE_SIGNATORY_FIELD]: tenant?.invoice_signatory || '',
    [INVOICE_TITLE_FIELD]: tenant?.invoice_title || '',
  };

  const form = useForm<z.infer<typeof formSchema>>({
    mode: 'all',
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const handleResetClick = async () => {
    openDialog({
      title: 'Atkurti duomenis iš atsarginės versijos',
      message: 'Ar tikrai norite atkurti duomenis iš atsarginės versijos?',
      confirmText: 'Atkurti',
      onConfirm: reset,
    });
  };

  const canReset = user?.id === 'cce5ebae-c70f-443d-8bbf-c89d5972aa24';

  useEffect(() => {
    if (!tenant) return;
    form.reset(defaultValues);
  }, [tenant]);

  async function onSubmit(formData: z.infer<typeof formSchema>): Promise<void> {
    await updateTenant(formData);
  };

  return (
    <>
      <h1 className="text-2xl my-4 font-bold">Nustatymai</h1>
      <div className="my-8">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <div className="grid gap-8 grid-cols-3">
              <FormField control={form.control} name={NAME_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">Pavadinimas*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
              <FormField control={form.control} name={TEL_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">Tel*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
              <FormField control={form.control} name={EMAIL_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">El. Paštas*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
              <FormField control={form.control} name={ADDRESS_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">Adresas*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
              <FormField control={form.control} name={CODE_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">Kodas*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
              <FormField control={form.control} name={VAT_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">PVM Kodas*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
              <FormField control={form.control} name={INVOICE_SERIES_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">Sąskaitos Serija*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
              <FormField control={form.control} name={INVOICE_TITLE_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">Sąskaitos Pavadinimas*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
              <FormField control={form.control} name={INVOICE_SIGNATORY_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">Sąskaitos Pasirašytojas*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
              <FormField control={form.control} name={FITOSANITARIAN_CODE_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">Fitosanitarinis Kodas*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
              <FormField control={form.control} name={CERTIFICATE_CODE_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">Sertifikato Kodas*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
              <FormField control={form.control} name={BANK_NAME_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">Banko Pavadinimas*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
              <FormField control={form.control} name={BANK_CODE_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">Banko Kodas*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
              <FormField control={form.control} name={BANK_ACCOUNT_FIELD} render={({ field }) => (
                <FormItem>
                  <FormLabel className="block">Banko Sąskaita*</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )} />
            </div>
            <div className="flex">
              <Button
                className="mr-4"
                type="submit"
              >
                Išsaugoti
              </Button>
            </div>
          </form>
        </Form>
        {canReset && (
          <div className="my-6">
            <p className="my-2">Atkurti duomenis iš atsarginės versijos</p>
            <Button variant='destructive' type='button' onClick={handleResetClick}>
              Atkurti
            </Button>
          </div>
        )}
      </div>
    </>
  )
}
