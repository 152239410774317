import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from '@/lib/zod-lt';

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useStore } from '@/store';

// import { PRODUCT_TYPES } from '@/constants'

const ID_FIELD = 'id';
const NAME_FIELD = 'name';
const LATIN_FIELD = 'latin';
const PRICE_FIELD = 'price';
const UNIT_FIELD = 'unit';
// const TYPE_FIELD = 'type';
// const AGE_FIELD = 'age';
// const CONTAINER_FIELD = 'container';
// const HEIGHT_FIELD = 'height';
// const KN_QUANTITY_FIELD = 'kn_quantity';
// const STOCK_FIELD = 'stock';


const productSchema = z.object({
  [ID_FIELD]: z.string().optional(),
  [NAME_FIELD]: z.string().min(2),
  [LATIN_FIELD]: z.string().optional(),
  [UNIT_FIELD]: z.string().optional(),
  // [PRICE_FIELD]: z.number(),
  [PRICE_FIELD]: z.string(),
  // [TYPE_FIELD]: z.string().optional(),
  // [AGE_FIELD]: z.string().optional(),
  // [CONTAINER_FIELD]: z.string().optional(),
  // [HEIGHT_FIELD]: z.string().optional(),
  // [KN_QUANTITY_FIELD]: z.number().optional(),
  // [STOCK_FIELD]: z.number().optional(),
});

type ProductFormProps = {
  onProductFormSubmit?: (product: z.infer<typeof productSchema>) => void
  // id?: string
  // name: string
  // latin?: string
  // unit?: string
  // price: number
  // type?: string
  // age?: string
  // container?: string
  // height?: string
  // kn_quantity?: number
  // stock?: number
}

export function ProductForm({ onProductFormSubmit, ...loaderData }: ProductFormProps): JSX.Element {
  const {
    products,
    upsertProduct,
    isUpsertProductLoading,
  } = useStore(s => s);

  const form = useForm<z.infer<typeof productSchema>>({
    mode: 'all',
    resolver: zodResolver(productSchema),
    defaultValues: {
      [ID_FIELD]: loaderData?.id,
      [NAME_FIELD]: loaderData?.name ?? '',
      [LATIN_FIELD]: loaderData?.latin ?? '',
      [PRICE_FIELD]: loaderData?.price?.toString() ?? '',
      [UNIT_FIELD]: loaderData?.unit ?? 'vnt.',
    },
  });

  async function onSubmit(formData: z.infer<typeof productSchema>): Promise<void> {
    const product = {
      ...formData,
      price: +formData.price,
    };

    const newProduct = await upsertProduct(product);
    onProductFormSubmit?.(newProduct);
  }

  if (!products) return <></>;

  const buttonIdleLabel = loaderData?.id ? 'Atnaujinti' : 'Sukurti';
  const buttonLoadingLabel = loaderData?.id ? 'Atnaujinama...' : 'Kuriama...';
  const buttonLabel = isUpsertProductLoading ? buttonLoadingLabel : buttonIdleLabel;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="w-full space-y-6"
      >
        <div className="grid grid-cols-2 gap-x-6 gap-y-3">
          <FormField control={form.control} name={NAME_FIELD} render={({ field }) => (
            <FormItem>
              <FormLabel>Pavadinimas*</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                Prekės / paslaugos pavadinimas
              </FormDescription>
              <FormMessage />
            </FormItem>
          )} />
          <FormField control={form.control} name={LATIN_FIELD} render={({ field }) => (
            <FormItem>
              <FormLabel>Lotyniškas pavadinimas</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                Botaninis pavadinimas
              </FormDescription>
              <FormMessage />
            </FormItem>
          )} />
          <FormField control={form.control} name={PRICE_FIELD} render={({ field }) => (
            <FormItem>
              <FormLabel>Kaina*</FormLabel>
              <FormControl>
                <Input type="number" {...field} />
              </FormControl>
              <FormDescription>
                Prekės / paslaugos kaina (<b>su PVM</b>)
              </FormDescription>
              <FormMessage />
            </FormItem>
          )} />
          <FormField control={form.control} name={UNIT_FIELD} render={({ field }) => (
            <FormItem>
              <FormLabel>Vnt. matas</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                Prekės / paslaugos matavimo vienetas
              </FormDescription>
              <FormMessage />
            </FormItem>
          )} />
          {form.getValues(ID_FIELD) && (
            <FormField control={form.control} name={ID_FIELD} render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input type="hidden" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )} />
          )}
        </div>
        <Button
          type="submit"
          className="w-full"
          size="lg"
          disabled={isUpsertProductLoading}
        >
          {buttonLabel}
        </Button>
      </form>
    </Form>
  )
}
