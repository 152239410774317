import { ColumnDef } from "@tanstack/react-table"
import { ArrowUpDown } from "lucide-react"
import { ReactNode } from 'react';

import { Database } from '@/types/supabase'
import { Button } from '@/components/ui/button'
import { Checkbox } from "@/components/ui/checkbox"
import { Link } from "react-router-dom";

export type ClientType = Database['public']['Tables']['clients']['Row']

const intl = new Intl.DateTimeFormat('lt-LT', { dateStyle: 'short' })
const toDate = (date: string) => date ? intl.format(new Date(date)) : '';
const toSumEur = (sum: number) => new Intl.NumberFormat('lt-LT', { style: 'currency', currency: 'EUR' }).format(sum);

interface SortableHeaderProps {
  column: {
    toggleSorting: (value: boolean) => void;
    getIsSorted: () => string | undefined;
  };
  children?: ReactNode;
}

const SortableHeader = ({ column, children }: SortableHeaderProps): JSX.Element => {
  const isSorted = column.getIsSorted() == "asc" ? true : false;
  return (
    <Button
      variant="ghost"
      className="p-0"
      onClick={() => column.toggleSorting(isSorted)}
    >
      {children}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  )
}

export const clientsColumns: ColumnDef<ClientType>[] = [{
  id: "select",
  header: ({ table }) => {
    return (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Pasirinkite visas eilutes"
      />
    )
  },
  cell: ({ row }) => (
    <Checkbox
      checked={row.getIsSelected()}
      onCheckedChange={(value) => row.toggleSelected(!!value)}
      aria-label="Pasirinkite eilutę"
    />
  ),
  enableSorting: false,
  enableHiding: false,
},
{
  accessorKey: "name",
  header: ({ column }) => <SortableHeader column={column}>Pavadinimas</SortableHeader>,
  cell: ({ row }) => {
    return (
      <Link
        to={`/clients/${row.original.id}`}
        className="whitespace-nowrap font-bold hover:underline"
      >
        {row.getValue("name")}
      </Link>
    )
  },
},
{
  accessorKey: "updated_at",
  header: ({ column }) => <SortableHeader column={column}>Atnaujinta</SortableHeader>,
  cell: ({ row }) => {
    return (
      <div className="whitespace-nowrap">
        {toDate(row.getValue("updated_at"))}
      </div>
    )
  },
},
{
  accessorKey: "created_at",
  header: ({ column }) => <SortableHeader column={column}>Sukurta</SortableHeader>,
  cell: ({ row }) => {
    return (
      <div className="whitespace-nowrap">
        {toDate(row.getValue("updated_at"))}
      </div>
    )
  },
},
{
  accessorKey: "code",
  header: ({ column }) => <SortableHeader column={column}>Kodas</SortableHeader>,
  cell: ({ row }) => {
    return (
      <Link
        to={`/clients/${row.original.id}`}
        className="whitespace-nowrap font-bold hover:underline"
      >
        {row.getValue("code")}
      </Link>
    )
  },
},
{
  accessorKey: "vat",
  header: ({ column }) => <SortableHeader column={column}>PVM</SortableHeader>,
  cell: ({ row }) => {
    return (
      <div className="whitespace-nowrap">
        {row.getValue("vat")}
      </div>
    )
  },
},
{
  accessorKey: "phone",
  header: ({ column }) => <SortableHeader column={column}>Telefonas</SortableHeader>,
  cell: ({ row }) => {
    return (
      <div className="whitespace-nowrap">
        {row.getValue("phone")}
      </div>
    )
  },
},
{
  accessorKey: "address",
  header: ({ column }) => <SortableHeader column={column}>Adresas</SortableHeader>,
  cell: ({ row }) => {
    return (
      <div className="whitespace-nowrap">
        {row.getValue("address")}
      </div>
    )
  },
},
{
  accessorKey: "contract_id",
  header: ({ column }) => <SortableHeader column={column}>Sutartis</SortableHeader>,
  cell: ({ row }) => {
    return (
      <div className="whitespace-nowrap">
        {row.getValue("contract_id")}
      </div>
    )
  },
},
{
  accessorKey: "type",
  header: ({ column }) => <SortableHeader column={column}>Tipas</SortableHeader>,
  cell: ({ row }) => {
    return (
      <div className="whitespace-nowrap">
        {row.getValue("type")}
      </div>
    )
  },
},
];
