import { useRouteLoaderData } from 'react-router';

import { InvoiceForm } from '@/components/InvoiceForm';
import { useStore } from '@/store'
import { UPDATE_INVOICE_ROUTE_ID } from '@/router';

export function UpdateInvoice(): JSX.Element {
  const { tenant } = useStore(s => s);
  const { is_draft, code, discarded } = useRouteLoaderData(UPDATE_INVOICE_ROUTE_ID);
  const invoiceNumber = `${tenant?.invoice_series} Nr.:${code}`

  return (
    <>
      <h1 className="text-2xl my-4 font-bold">
        <span className={discarded ? 'opacity-50' : ''}>Sąskaita {invoiceNumber}</span>
        {discarded && (<span className="inline-block ml-2  text-red-500 italic">[Sugadinta]</span>)}
        {is_draft && (<span className="inline-block ml-2 font-light text-gray-500 italic">(Juodraštis)</span>)}
      </h1>
      <InvoiceForm />
    </>
  );
}
