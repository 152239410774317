import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { useNavigate, useRouteLoaderData } from 'react-router-dom'

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { REPORTS_ROUTE_ID } from '@/router'
import { useStore } from '@/store';
import { Database } from '@/types/supabase';

export type InvoiceType = Database['public']['Tables']['invoices']['Row']

interface ReportsDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[],
}

const DEFAULT_SORT = { id: 'code', desc: true };

export function ReportsDataTable<TData, TValue>({
  columns,
  data,
}: ReportsDataTableProps<TData, TValue>) {
  const currentMonthIndex = new Date().getMonth();
  const [selectedMonthIndex, setSelectedMonthIndex] = useState<Number>(currentMonthIndex);
  const [sorting, setSorting] = useState<SortingState>([DEFAULT_SORT])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const loaderData = useRouteLoaderData(REPORTS_ROUTE_ID);
  const { invoices } = useStore(s => s);

  function handleSelectMonth(value: string) {
    setSelectedMonthIndex(Number(value));
    // setColumnFilters([
    //   {
    //     id: 'date',
    //     value: `${new Date().getFullYear()}-${Number(value) + 1}-01`,
    //     filterFn: (row, accessorKey, filterValue) => {
    //       console.log(row, accessorKey, filterValue);
    //       const date = row.values[accessorKey];
    //       return date.includes(filterValue);

    //     },
    //   },
    // ]);
  }

  // const [rowSelection, setRowSelection] = useState({})
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    // getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // onColumnVisibilityChange: setColumnVisibility,
    // onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      // columnVisibility,
      // rowSelection,
    },
    filterFns: {
      dater: (rows, id, filterValue) => {
        console.log({ rows, id, filterValue })
        return rows?.filter(row => {
          const date = new Date(row.original.date);
          return date.getMonth() === Number(filterValue);
        });
    },
  },
  });

  const MONTHS = [
    { label: 'Sausis', value: 0 },
    { label: 'Vasaris', value: 1 },
    { label: 'Kovas', value: 2 },
    { label: 'Balandis', value: 3 },
    { label: 'Gegužė', value: 4 },
    { label: 'Birželis', value: 5 },
    { label: 'Liepa', value: 6 },
    { label: 'Rugpjūtis', value: 7 },
    { label: 'Rugsėjis', value: 8 },
    { label: 'Spalis', value: 9 },
    { label: 'Lapkritis', value: 10 },
    { label: 'Gruodis', value: 11 },
  ];

  return (
    <div>
      <div className="flex items-center justify-between py-4">
        <Select onValueChange={handleSelectMonth} defaultValue={'field.value'}>
          <SelectTrigger>
            <SelectValue placeholder="Pasirinkite mėnesį" />
          </SelectTrigger>
          <SelectContent>
            {MONTHS.map((month) => (
              <SelectItem key={month.value} value={month.value}>
                {month.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {/* <div className="flex-1 text-sm text-muted-foreground">
        {table.getFilteredSelectedRowModel().rows.length} of{" "}
        {table.getFilteredRowModel().rows.length} row(s) selected.
      </div> */}
    </div>
  )
}
