import { useRouteLoaderData } from 'react-router-dom'

import { ClientForm } from '@/components/ClientForm'
import { UPDATE_CLIENT_ROUTE_ID } from '@/router'

interface ClientLoaderData {
  id: number
  name: string
  contact_name: string
  code: string
  vat: string
  phone: string
  address: string
  contract_id: string
  tenant_id: number
  type: string
}

export function UpdateClient(): JSX.Element {
  const data = useRouteLoaderData(UPDATE_CLIENT_ROUTE_ID);

  return (
    <>
      <ClientForm {...data as ClientLoaderData} />
    </>
  )
}
